import React from "react";

const WishlistIcon = () => {
  return (
    <>
      <svg
        id="heart_2_"
        data-name="heart (2)"
        xmlns="http://www.w3.org/2000/svg"
        width="19.452"
        height="18"
        viewBox="0 0 19.452 18"
      >
        <g id="Group_2" data-name="Group 2" transform="translate(0 0)">
          <path
            id="Path_4"
            data-name="Path 4"
            d="M14.209,39.221a4.958,4.958,0,0,0-4.1,2.28c-.142.2-.269.4-.381.591-.112-.193-.239-.392-.381-.591a4.958,4.958,0,0,0-4.1-2.28C2.186,39.221,0,42.018,0,45.374,0,49.212,2.878,52.828,9.332,57.1a.705.705,0,0,0,.787,0c6.454-4.273,9.332-7.889,9.332-11.727C19.452,42.02,17.268,39.221,14.209,39.221Zm1.716,10.931a28.823,28.823,0,0,1-6.2,5.265,28.824,28.824,0,0,1-6.2-5.265A7.547,7.547,0,0,1,1.52,45.374c0-2.416,1.494-4.492,3.723-4.492a3.472,3.472,0,0,1,2.877,1.6A6.381,6.381,0,0,1,9,44.22a.743.743,0,0,0,1.451,0,6.376,6.376,0,0,1,.854-1.7,3.486,3.486,0,0,1,2.9-1.641c2.231,0,3.723,2.078,3.723,4.492A7.547,7.547,0,0,1,15.925,50.152Z"
            transform="translate(0 -39.221)"
            fill="#171717"
          ></path>
        </g>
      </svg>
    </>
  );
};

export default WishlistIcon;
