import { ProductsType } from '@/interFace/interFace';
import { RootState } from '@/redux/store';
import { useSelector } from 'react-redux';

// Hook to count unique products in the cart
export const useUniqueProductCount = () => {
  const cartProducts = useSelector((state: RootState) => state?.cart?.cartProducts || []);
  const uniqueProductIds = new Set<number>();

  // Loop through cart products and add unique product IDs to the Set
  for (const product of cartProducts) {
    if (product && product.id) { // Ensure product and product.id are valid
      uniqueProductIds.add(product.id);
    }
  }

  // Return the number of unique products
  return uniqueProductIds.size;
};

// Hook to count unique products in the wishlist
export const useUniqueWishlistCount = () => {
  const wishlistProducts = useSelector((state: RootState) => state?.wishlist?.cartProducts || []);
  const uniqueProductIds = new Set<number>();

  // Loop through wishlist products and add unique product IDs to the Set
  for (const product of wishlistProducts) {
    if (product && product.id) { // Ensure product and product.id are valid
      uniqueProductIds.add(product.id);
    }
  }

  // Return the number of unique products in the wishlist
  return uniqueProductIds.size;
};

// Hook to calculate the total quantity of products in the cart
export const useTotalProductCount = () => {
  const cartProducts = useSelector((state: RootState) => state?.cart?.cartProducts || []);
  let totalCount = 0;

  // Loop through cart products and sum up the totalCart values
  for (const product of cartProducts) {
    if (product && product.totalCart) { // Ensure product and totalCart are valid
      totalCount += product.totalCart;
    }
  }

  // Return the total count of products in the cart
  return totalCount;
};

// Alternatively, using reduce to calculate total quantity of products in the cart
export const useTotalProductCountWithReduce = () => {
  const cartProducts = useSelector((state: RootState) => state?.cart?.cartProducts || []);
  
  // Safely calculate total count using reduce
  const totalCount = (cartProducts || []).reduce((acc, product) => {
    return acc + (product?.totalCart || 0); // Safeguard for undefined or missing totalCart
  }, 0);

  return totalCount;
};

// Hook to calculate the total quantity of products in the wishlist
export const useTotalProductWishlistCount = () => {
  const wishlistProducts = useSelector((state: RootState) => state?.wishlist?.cartProducts || []);
  let totalCount = 0;

  // Loop through wishlist products and sum up the totalCart values
  for (const product of wishlistProducts) {
    if (product && product.totalCart) { // Ensure product and totalCart are valid
      totalCount += product.totalCart;
    }
  }

  // Return the total count of products in the wishlist
  return totalCount;
};

// Alternatively, using reduce to calculate total quantity of products in the wishlist
export const useTotalProductWishlistCountWithReduce = () => {
  const wishlistProducts = useSelector((state: RootState) => state?.wishlist?.cartProducts || []);
  
  // Safely calculate total count using reduce
  const totalCount = (wishlistProducts || []).reduce((acc, product) => {
    return acc + (product?.totalCart || 0); // Safeguard for undefined or missing totalCart
  }, 0);

  return totalCount;
};

// Hook to count unique products in the comparison list
export const useUniqueCompareCount = () => {
  const compareProducts = useSelector((state: RootState) => state?.compare?.cartProducts || []);
  const uniqueProductIds = new Set<number>();

  // Loop through compare products and add unique product IDs to the Set
  for (const product of compareProducts) {
    if (product && product.id) { // Ensure product and product.id are valid
      uniqueProductIds.add(product.id);
    }
  }

  // Return the number of unique products in the comparison list
  return uniqueProductIds.size;
};
